
































import {FirstPage} from "@/request/shop/firstPage";
import el_add_goods from '@/components/first-page-components/el-add-goods.vue';

/**
 * 商品第一种展示形式
 */
export default {
    name: "Goods1List",
    props: {
        id: {
            type: String
        }
    },
    data() {
        let self: any = this;
        return {
            list: []
        }
    },
    components: {
        'el-add-goods': el_add_goods
    },
    created() {
        let self: any = this;
        self.getData();
    },
    methods: {
        getData() {
            let self: any = this;
            FirstPage.getGoodsList(self.id).then((body: any) => {
                if (body['code'] === 0) {
                    self.list= body.data;
                }
            })
        },
        add() {
            let self: any = this;
            self.$refs['add-goods'].show();
        },
        onSubmit(goods: any) {
            let self: any = this;
            FirstPage.saveGoods({pageId: self.id, goodsId: goods.id}).then((body: any) => {
                if (body['code'] === 0) {
                    self.getData();
                    self.$parent.$emit('on-add')
                }
            })
        },
        remove(id: string) {
            let self: any = this;
            FirstPage.deleteGoods(id).then((body: any) => {
                if (body['code'] === 0) {
                    self.getData();
                    self.$parent.$emit('on-remove')
                }
            })
        },
        update(row: any) {
            let self: any = this;
            FirstPage.updateGoodsSort(row.id, row.sort).then((body: any) => {
                if (body['code'] === 0) {
                    self.getData();
                }
            })
        },
        onPopperShow() {
            let self: any = this;
            self.$forceUpdate();
        }
    }
}
