























/**
* 专为首页配置添加商品用
* */
import {Goods} from "@/request/goods/Goods";

export default {
    name: "el-add-goods",
    data() {
        let self: any = this;
        return {
            addModal: {
                show: false,
                loading: false,
                searchLoading: false,
                list: [],
                searchMethod(query: string) {
                    self.searchLoading = true;
                    Goods.summaryList(query).then((body: any) => {
                        self.searchLoading = false;
                        if (body['code'] === 0) {
                            self.addModal.list = body.data;
                        }
                    })
                }
            },
            emitData: {}
        }
    },
    methods: {
        show() {
            let self: any = this;
            self.addModal.show = true;
        },
        onChange(value: string) {
            let self: any = this;
            self.emitData = self.addModal.list.filter((j: any) => j.id === value)[0];
        },
        onOk() {
            let self: any = this;
            self.$emit('on-ok', self.emitData);
        }
    }
}
